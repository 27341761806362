import React from "react";
import styled from "styled-components";
import { Box } from "@envato/eds";

export type ContentSize = "small" | "medium" | "large";

const Container = styled.div`
  background-color: ${(props) => props.theme.bgPrimary};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    background-color: ${(props) => props.theme.bgSecondary};
  }
`;

const Inner = styled.div<{ size: ContentSize }>`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.theme.widthContentSmall};
  margin: auto;

  ${(props) => props.size === "large" && `max-width: 100%;`}

  flex-direction: column;

  padding: ${(props) => props.theme.spacing3x};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    padding: ${(props) => props.theme.spacing5x};
  }

  @media (min-width: ${(props) => props.theme.breakLarge}) {
    padding: ${(props) => props.theme.spacing7x};
    flex-direction: row;
    align-items: flex-start;

    ${(props) =>
      props.size === "medium" &&
      `max-width: ${props.theme.widthContentMedium};`}

    ${(props) =>
      props.size === "large" && `max-width: ${props.theme.widthContentLarge};`}
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Card = styled(Box).attrs((props) => ({
  padding: props.padding || ["none", "6x"],
}))`
  @media (min-width: ${(props) => props.theme.breakMedium}) {
    background: ${(props) => props.theme.cardBackground};

    border: ${(props) => props.theme.cardBorderWidth} solid
      ${(props) => props.theme.cardBorder};
    border-radius: ${(props) => props.theme.radiusBase};
  }
`;

interface MainContentProps {
  size?: ContentSize;
  children?: React.ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({
  size = "large",
  children,
}) => {
  return (
    <Container>
      <Inner size={size}>{children}</Inner>
    </Container>
  );
};

export default MainContent;
