import React from "react";
import styled from "styled-components";

import { Box, Stack } from "@envato/design-system/components";
import { Text } from "@envato/eds";

import MainContent, {
  Column,
  Card,
  ContentSize,
} from "../../components/MainContent";
import Header, { HeaderProps } from "../../components/Header";
import Footer from "../../components/Footer";

interface LayoutProps extends HeaderProps {
  title: string;
  testId: string;
  size: ContentSize;
  titleButton?: React.ReactNode;
  children?: React.ReactNode;
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Layout: React.FC<LayoutProps> = ({
  title,
  testId,
  size,
  variant,
  titleButton,
  children,
}) => {
  return (
    <Box
      containerType="inline-size"
      display="flex"
      flexDirection="column"
      minHeight="viewport"
    >
      <Header variant={variant} />
      <Box flexGrow="1">
        <MainContent size={size}>
          <Column>
            <Card>
              <Stack spacing="4x">
                <Title>
                  <Text variant="title-3" text={title} testId={testId} />
                  {titleButton}
                </Title>
                {children}
              </Stack>
            </Card>
          </Column>
        </MainContent>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
