import React from "react";

import { Text, HighlightLink } from "@envato/eds";

import SingleColumn from "../../../layouts/SingleColumn";

export interface ViewProps {
  newSupportTicketLink: string;
}

const onclick = () => {
  window.history.back();
  return false;
};

const View: React.FC<ViewProps> = ({ newSupportTicketLink }) => {
  return (
    <SingleColumn
      title="exceptions.notFound.title"
      testId="notFoundTitle"
      size="large"
    >
      <Text variant="body-large" text="exceptions.notFound.message" />
      <Text
        variant="body-large"
        text="exceptions.notFound.links"
        values={{
          backLink: (
            <HighlightLink
              to="#"
              onClick={onclick}
              text="exceptions.notFound.backLink"
            />
          ),
          contactSupportLink: (
            <HighlightLink
              to={newSupportTicketLink}
              text="exceptions.notFound.contactSupportLink"
              target="_blank"
            />
          ),
        }}
      />
    </SingleColumn>
  );
};

export default View;
