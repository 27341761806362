import React from "react";

import { useExternalLink } from "@envato/sso-common";

import View from "./View";

export { default as routeMetaData } from "./routeMetaData";

const NotFound = () => {
  const newSupportTicketLink = useExternalLink("newSupportTicket");

  return <View newSupportTicketLink={newSupportTicketLink} />;
};

export default NotFound;
